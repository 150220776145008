import React, { useState, useRef, useEffect } from "react";
import { Button, Modal } from "antd";
import iconFilter from "../assets/icon/icon_filter.png";
import "./modal.less";

const App = ({ content, btnCustom = null, modalCustom = null }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const buttonRef = useRef(null);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	// Check if the screen width is mobile and update the state
	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		// Initial check
		handleResize();

		// Listen to window resize
		window.addEventListener("resize", handleResize);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	// Prevent scrolling on mobile when the modal is open
	useEffect(() => {
		if (isModalOpen) {
			document.body.classList.add("no-scroll");
		} else {
			document.body.classList.remove("no-scroll");
		}

		// Cleanup function to remove the class on unmount
		return () => {
			document.body.classList.remove("no-scroll");
		};
	}, [isModalOpen, isMobile]);

	const modalWidth = 280; // Define your modal width here

	return (
		<>
			<Button
				type="primary"
				onClick={showModal}
				ref={buttonRef}
				className={
					btnCustom ? btnCustom : isModalOpen ? "resetBtnSelected" : "resetBtn"
				}
			>
				<img
					style={{ marginRight: "5px" }}
					src={iconFilter}
					alt="Filter Icon"
				/>{" "}
				Filter
			</Button>
			{isModalOpen && (
				<Modal
					open={true}
					onOk={handleOk}
					onCancel={handleCancel}
					getContainer={false}
					footer={null}
					header={null}
					className={modalCustom ? modalCustom : "filter-modal"}
					style={{
						position: "absolute",
						top: buttonRef.current.getBoundingClientRect().bottom / 0.75, // Adjust for zoom
						left:
							(buttonRef.current.getBoundingClientRect().left - modalWidth) /
							0.75, // Center modal under button
						width: "fit-content",
						maxWidth: "480px",
					}}
					mask={isMobile} // Apply mask only on mobile
					maskClosable={true} // Allow closing the modal by clicking on the mask
				>
					{content}
				</Modal>
			)}
		</>
	);
};

export default App;
