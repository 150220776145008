import React, { useEffect, useState } from "react";
import { Table, Checkbox, Modal, Button, Card, DatePicker, Form } from "antd";
import E from "../../../../models/E";
import { find, union } from "lodash";
import CustomEmpty from "@/utils/CustomEmpty";
import moment from "moment";
const { RangePicker } = DatePicker;
export default (props) => {
	const [editForm] = Form.useForm();
	const { visible, subscribe } = props;
	const [module, setModule] = useState([
		{ id: 5, name: "IQ Exclusives", value: "Exclusives" },
		{ id: 1, name: "VANA", value: "InsightsFeed" },
		{ id: 10, name: "Market Model", value: "MarketModel" },
		{ id: 2, name: "Esports", value: "Esport" },
		{ id: 7, name: "Games", value: "Games" },
		{ id: 8, name: "Organizations", value: "Organizations" },
		{ id: 3, name: "Financial Data", value: "Finacial" },
		{ id: 9, name: "Internet iCafe", value: "ChinaICafe" },
		// { id: 4, name: 'China streaming', value: 'ChinaStreaming' },
		// { id: 6, name: 'Transactions', value: 'Transactions' },
	]);
	//组件渲染时根据变量“subscribe”和“visible”更新“module”变量的值，并更新antd表单中的时间字段。 | During component rendering, update the value of the "module" variable based on the variables "subscribe" and "visible", and update the time field in the ant form.
	useEffect(() => {
		if (visible) {
			if (subscribe && subscribe?.module) {
				for (let i in subscribe?.module) {
					let item = find(module, { value: i });
					if (item) {
						item.region = subscribe?.module[i];
					}
				}
				setModule(module.concat());
			} else {
				setModule([
					{ id: 5, name: "IQ Exclusives", value: "Exclusives" },
					{ id: 1, name: "VANA", value: "InsightsFeed" },
					{ id: 10, name: "Market Model", value: "MarketModel" },
					{ id: 2, name: "Esports", value: "Esport" },
					{ id: 7, name: "Games", value: "Games" },
					{ id: 8, name: "Organizations", value: "Organizations" },
					{ id: 3, name: "Financial Data", value: "Finacial" },
					{ id: 9, name: "Internet iCafe", value: "ChinaICafe" },
					// { id: 4, name: 'China streaming', value: 'ChinaStreaming' },
					// { id: 6, name: 'Transactions', value: 'Transactions' },
				]);
			}
			editForm?.setFieldsValue({
				time:
					(subscribe && [
						moment(subscribe?.subscribeDate),
						moment(subscribe?.expiredDate),
					]) ||
					null,
			});
		}
	}, [subscribe, visible]);
	const renderEditSubScription = () => {
		const columns = [
			{ title: "Buckets / Section", dataIndex: "name", key: "name" },
			{
				title: E?.mainRegion?.China,
				dataIndex: "China",
				key: "China",
				align: "center",
				render: (text, record, index) => {
					return (
						<Checkbox
							className="custom-checkbox"
							checked={
								record?.region &&
								record?.region?.indexOf(E?.mainRegion?.China) > -1
									? true
									: false
							}
						/>
					);
				},
			},
			{
				title: E?.mainRegion?.EastAsia,
				dataIndex: "Ease Asia",
				key: "Ease Asia",
				align: "center",
				render: (text, record, index) => {
					return (
						record?.name !== "China streaming" && (
							<Checkbox
								className="custom-checkbox"
								checked={
									record?.region &&
									record?.region?.indexOf(E?.mainRegion?.EastAsia) > -1
										? true
										: false
								}
							/>
						)
					);
				},
			},
			{
				title: E?.mainRegion?.SEA,
				dataIndex: "SEA",
				key: "SEA",
				align: "center",
				render: (text, record, index) => {
					return (
						record?.name !== "China streaming" && (
							<Checkbox
								className="custom-checkbox"
								checked={
									record?.region &&
									record?.region?.indexOf(E?.mainRegion?.SEA) > -1
										? true
										: false
								}
							/>
						)
					);
				},
			},
			{
				title: E?.mainRegion?.MENA,
				dataIndex: "MENA",
				key: "MENA",
				align: "center",
				render: (text, record, index) => {
					return (
						record?.name !== "China streaming" && (
							<Checkbox
								className="custom-checkbox"
								checked={
									record?.region &&
									record?.region?.indexOf(E?.mainRegion?.MENA) > -1
										? true
										: false
								}
							/>
						)
					);
				},
			},
			{
				title: E?.mainRegion?.India,
				dataIndex: "India",
				key: "India",
				align: "center",
				render: (text, record, index) => {
					return (
						record?.name !== "China streaming" && (
							<Checkbox
								className="custom-checkbox"
								checked={
									record?.region &&
									record?.region?.indexOf(E?.mainRegion?.India) > -1
										? true
										: false
								}
							/>
						)
					);
				},
			},
			{
				title: E?.mainRegion?.Global,
				dataIndex: "Global",
				key: "Global",
				align: "center",
				render: (text, record, index) => {
					return (
						record?.name !== "China streaming" && (
							<Checkbox
								className="custom-checkbox"
								checked={
									record?.region &&
									record?.region?.indexOf(E?.mainRegion?.Global) > -1
										? true
										: false
								}
							/>
						)
					);
				},
			},
			{
				title: "ALL",
				dataIndex: "ALL",
				key: "ALL",
				align: "center",
				render: (text, record, index) => {
					return (
						<Checkbox
							className="custom-checkbox"
							indeterminate={
								record?.region && record?.region?.length !== 6 ? true : false
							}
							checked={
								record?.region && record?.region?.length === 6 ? true : false
							}
						/>
					);
				},
			},
		];
		return (
			<Card bordered={false}>
				<Form form={editForm}>
					<Form.Item label="Duration" name="time">
						<RangePicker disabled />
					</Form.Item>
				</Form>
				<Table
					locale={{ emptyText: CustomEmpty }}
					scroll={{ x: "max-content" }}
					bordered
					columns={columns}
					dataSource={module}
					pagination={false}
					rowKey="id"
				/>
			</Card>
		);
	};
	return <Modal {...props}>{renderEditSubScription()}</Modal>;
};
