import React, { useState, useEffect } from "react";
import moment from "moment";
import "./index.less";
import {
	Row,
	Col,
	Typography,
	Tag,
	Table,
	Empty,
	Button,
	Spin,
	Image,
} from "antd";
import { effect, useStore, reducer } from "../../../utils/dva16";
import { uniqBy } from "lodash";
import { Navigation } from "swiper";
import defaultLogo from "../../../assets/images/company/defaul-logo_organization@2x.png";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import CustomEmpty from "@/utils/CustomEmpty";
import "swiper/swiper-bundle.min.css";
import {
	EGet,
	EGetOne,
	NBlog,
	NCompany,
	NFreeReport,
	EGetDetail,
	NInsightsFeed,
	RSetState,
	NMAIPO,
	NExclusives,
	EPost,
	NOrganizationMulti,
	MName,
} from "../../../models/contants";
import imgSource from "../../../assets";
import E from "../../../models/E";
import { customizeRenderEmpty } from "../../../utils/utils";
import { Helmet } from "react-helmet";
import { DoubleRightOutlined } from "@ant-design/icons";
import formatDate from "@/utils/dateFormater";

const { Link } = Typography;

export default (props) => {
	const { id } = props.match.params;
	const { history } = props;
	const { organizationsDetailData, loading } = useStore(NOrganizationMulti);
	const { InsightsFeedList, EsportsList } = useStore(NInsightsFeed);
	const { MAIPOList } = useStore(NMAIPO);
	const { exclusivesList } = useStore(NExclusives);
	const [gameData, setGameData] = useState([]);
	const [gameDataLength, setGameDataLength] = useState(0);
	const [showAllTags, setShowAllTags] = useState({});

	const toggleShowAll = (index) => {
		setShowAllTags((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};

	useEffect(() => {
		effect(NOrganizationMulti, EGetDetail, { id });
		effect(NMAIPO, EGet, { page: 1, perPage: 5, company: id });
		effect(NInsightsFeed, EGet, {
			company: id,
			page: 1,
			perPage: 5,
		});
		effect(NExclusives, EGet, { company: id });
		effect(NInsightsFeed, EGetOne, {
			type: "Esports",
			company: id,
			page: 1,
			perPage: 5,
		});
		return () => {
			reducer(NCompany, RSetState, {
				organizationsDetailData: null,
				companyStream: null,
			});
			reducer(NBlog, RSetState, { BlogList: null });
			reducer(NInsightsFeed, RSetState, {
				InsightsFeedList: null,
				EsportsList: null,
			});
			reducer(NFreeReport, RSetState, { reportPage: null });
			reducer(NExclusives, RSetState, { exclusivesList: null });
		};
	}, [id]);

	useEffect(() => {
		if (organizationsDetailData) {
			let { developerGames, publisherGames } = organizationsDetailData;

			let list = developerGames?.concat(publisherGames);
			//去重数组中相同id的数据

			setGameData(uniqBy(list, "id"));
		}
	}, [organizationsDetailData]);

	useEffect(() => {
		if (gameData.length > 2) {
			setGameDataLength(3);
		} else {
			setGameDataLength(1);
		}
	}, [gameData]);
	// 组件渲染时滚动到页面的顶部 | Scroll to the top of the page during component rendering
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		effect(MName, EGet, {
			modelName: organizationsDetailData?.name,
		});
	}, [organizationsDetailData?.name]);
	const onAction = (id, mode, url, title) => {
		if (mode == E?.operate?.Share) {
			effect(NExclusives, EPost, { exclusivesID: id, type: E?.operate?.Share });
			const body = `Hello, \n\n Please copy this link to share the NikoIQ Exclusives report:${url} \n\n Thank You`;
			window.open(
				`https://outlook.live.com/owa/?path=/mail/action/compose&to=&subject=${`NikoIQ Exclusives-${title}`}&body=${encodeURIComponent(
					body
				)}`
			);
		} else {
			effect(
				NExclusives,
				EPost,
				{ exclusivesID: id, type: E?.operate?.Download },
				() => {}
			);
			window.open(url);
		}
	};

	const date = new Date(
		organizationsDetailData && organizationsDetailData?.founded
	);
	const formattedDate = formatDate(date);

	const copyVana = (e, vo) => {
		e.preventDefault();
		if (vo?.content) {
			navigator.clipboard
				.writeText(vo?.content?.replace(/<[^>]+>/g, ""))
				.then(() => {
					console.log("Content copied to clipboard!");
				})
				.catch((err) => {
					console.error("Failed to copy: ", err);
				});
		}
	};

	const dataBlock = (v, i) => {
		return (
			<div className="data_item" key={i}>
				<div className="head">
					<span
						onClick={() => {
							history.push({
								pathname: "/nikoiq_exclusives",
								state: { company: id },
							});
						}}
						className="title"
					>
						{v?.title}
					</span>
					<span className="time">{v?.date}</span>
				</div>
				<div className="mid">{v?.subHeader}</div>
				<div className="mid">{v?.title}</div>
				<div className="bottom">
					<div
						className="action"
						onClick={() => {
							onAction(v?.id, E?.operate?.Download, v?.pdf, v?.title);
						}}
					>
						<img src={imgSource?.icon_pdf}></img>
						<span>Click here for PDF</span>
					</div>
					<div
						className="action"
						onClick={() => {
							onAction(v?.id, E?.operate?.Share, v?.pdf, v?.title);
						}}
					>
						<img src={imgSource?.icon_share}></img>
						<span>Share</span>
					</div>
				</div>
			</div>
		);
	};
	// ---------------------渲染-------------------------
	return (
		<div className="CompanyDetail_Box  wd100" style={{ padding: "20px 0" }}>
			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>
				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          			gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
        	`}
				</script>
			</Helmet>
			<Spin spinning={loading} size="large">
				<div className="CompanyBox mt50">
					<Row className="RowBox mt30">
						<Col span={12}>
							<div className="Detail_Title_Box">
								<div className="companyDtIco">
									<img
										className="Tabel_Company_Logo"
										src={
											organizationsDetailData?.logo
												? organizationsDetailData.logo
												: defaultLogo
										}
										alt={organizationsDetailData?.name}
									/>
								</div>
								<div className="companyDttitle">
									<h1>{organizationsDetailData?.name}</h1>
									<div>
										{organizationsDetailData?.withGamesList?.map((vi, iii) => (
											<span
												className="game-title"
												onClick={() => {
													history.push(
														`/games_organizations/games/details/${vi?.id}`
													);
												}}
											>
												{vi?.gameEn}
											</span>
										))}
									</div>
									{organizationsDetailData?.website && (
										<p>
											<Link
												href={organizationsDetailData?.website || "#"}
												target="_blank"
												className="website-link"
											>
												<img src={imgSource?.external_link_icon}></img>
												Website
											</Link>
										</p>
									)}
								</div>
							</div>
						</Col>
						<Col span={12}>
							<div className="left-column">
								<div>
									<span className="item-label">HQ Location:</span>
									<span className="item-value">
										{organizationsDetailData?.withArea?.length > 0
											? organizationsDetailData?.withArea?.join(", ")
											: "-"}
									</span>
								</div>
								<div>
									<span className="item-label">Region:</span>
									<span className="item-value">
										{organizationsDetailData?.region?.join(", ") || "-"}
									</span>
								</div>
								<div>
									<span className="item-label">Type:</span>
									<span className="item-value">
										{organizationsDetailData?.type || "-"}
									</span>
								</div>
								<div>
									<span className="item-label">Stock Code:</span>
									<span className="item-value">
										{organizationsDetailData?.tickerSymbol || "-"}
									</span>
								</div>
								<div>
									<span className="item-label">Founded:</span>
									<span className="item-value">
										{formattedDate ? formattedDate : "-"}
									</span>
								</div>
							</div>
						</Col>
					</Row>
				</div>
				{/* VANA */}
				<div className="BlogBox mt50">
					<div className="RowBox">
						<div className="Global_title">
							<h2>Insights</h2>
						</div>
						<div className="Insights_List">
							<ul className="ProlistBox">
								{InsightsFeedList?.error != 1009 &&
									InsightsFeedList?.total == 0 && <CustomEmpty />}
								{InsightsFeedList?.data && InsightsFeedList?.error != 1009
									? InsightsFeedList?.data?.slice(0, 3)?.map((vo, i) => {
											const date = new Date(vo?.date);
											const formattedDate = formatDate(date);
											const showAll = showAllTags[i] || false;
											return (
												<li key={i}>
													<div className="insight_list_cont">
														<h3>
															<div>
																{/* <span
																	onClick={() => {
																		history.push({
																			pathname: "/news_articles/VANA",
																			state: { type: vo?.type },
																		});
																	}}
																>
																	[{vo?.type?.trim()}]
																</span> */}
																<div
																	style={{ cursor: "pointer" }}
																	onClick={() => {
																		history.push(
																			`/news_articles/vana/details/${vo?.id}`
																		);
																	}}
																>
																	{vo?.title}
																</div>
															</div>

															<div className="insight_list_date_style">
																{formattedDate}
															</div>
														</h3>
														<div style={{ display: "flex" }}>
															{vo?.area?.length > 0 && (
																<span
																	className="listLink"
																	onClick={() => {
																		history.push({
																			pathname: "/news_articles/VANA",
																			state: { area: vo?.area },
																		});
																	}}
																>
																	<img
																		src={imgSource?.map_icon}
																		style={{ marginRight: "5px" }}
																	></img>
																	{vo?.area?.length > 0 && vo?.area?.join(", ")}
																</span>
															)}
															{vo?.region?.length > 0 && (
																<span
																	className="listLink"
																	onClick={() => {
																		history.push({
																			pathname: "/news_articles/VANA",
																			state: {
																				region: vo?.region,
																			},
																		});
																	}}
																>
																	<img
																		src={imgSource?.globe_gray_icon}
																		style={{ marginRight: "5px" }}
																	></img>
																	{vo?.region?.length > 0 &&
																		vo?.region?.join(", ")}
																</span>
															)}
															{vo?.type && (
																<span
																	className="listLink"
																	onClick={() => {
																		history.push({
																			pathname: "/news_articles/VANA",
																			state: {
																				type: vo?.type,
																				subType: vo?.subType,
																			},
																		});
																	}}
																>
																	<img
																		src={imgSource?.folder_icon}
																		style={{ marginRight: "5px" }}
																	></img>
																	{vo?.type}
																</span>
															)}
														</div>
														<div className="Tag_List_Box">
															{vo?.withGames
																?.slice(0, showAll ? vo?.withGames?.length : 3)
																?.map((vi, iii) => (
																	<span
																		className="game-title"
																		key={iii}
																		onClick={() => {
																			history.push(
																				`/games_organizations/games/details/${vi?.id}`
																			);
																		}}
																	>
																		{vi?.gameEn}
																	</span>
																))}
															{vo?.withCompanys
																?.slice(
																	0,
																	showAll ? vo?.withCompanys?.length : 3
																)
																?.map((vi, ii) => (
																	<span
																		className="org-title"
																		key={ii}
																		onClick={() => {
																			history.push(
																				`/games_organizations/organizations/details/${vi?.id}`
																			);
																		}}
																	>
																		{vi?.name}
																	</span>
																))}
															{(vo?.withCompanys?.length > 3 ||
																vo?.withGames?.length > 3) && (
																<span
																	className="show-all"
																	onClick={() => toggleShowAll(i)}
																>
																	{showAll ? "Show less..." : "Show all..."}
																</span>
															)}
														</div>
														<div
															className="insght_lst_info"
															onClick={() => {
																history.push(
																	`/news_articles/vana/details/${vo?.id}`
																);
															}}
														>
															{vo?.content?.replace(/<[^>]+>/g, "")}
														</div>
														<div className="link">
															<a
																href={vo?.link}
																target="_blank"
																onClick={(e) => copyVana(e, vo)}
																style={{ marginRight: "2rem" }}
															>
																<img
																	src={imgSource?.copy_icon}
																	style={{ marginRight: "5px" }}
																></img>
																<span>Copy VANA</span>
															</a>
															{vo?.link && (
																<a href={vo?.link} target="_blank">
																	<img
																		src={imgSource?.external_link_icon}
																		style={{ marginRight: "5px" }}
																	></img>
																	<span>Visit Website</span>
																</a>
															)}
														</div>
													</div>
												</li>
											);
									  })
									: customizeRenderEmpty()}
							</ul>
						</div>
						{InsightsFeedList?.data?.length > 3 && (
							<div className="viewWrapper">
								<Button
									className="secReset moreBtn mt20"
									type="default"
									htmlType="button"
									onClick={() => {
										history.push({
											pathname: "/news_articles/VANA",
											state: {
												company: id,
												name: organizationsDetailData?.name,
											},
										});
									}}
								>
									View more
									<img src={imgSource?.right_arrow_blue}></img>
								</Button>
							</div>
						)}
					</div>
				</div>
				{/* Exclusives */}
				{/* <div className="BlogBox mt50">
					<div className="RowBox">
						<div className="Global_title">
							<h2>NikoIQ Exclusives</h2>
						</div>
						<div className="data_list">
							{exclusivesList?.data?.slice(0, 3)?.map((v, i) => {
								return dataBlock(v, i);
							})}
							{exclusivesList?.total == 0 && exclusivesList?.error != 1009 && (
								<CustomEmpty />
							)}
							<Row>
								<Col
									span={24}
									style={{ textAlign: "right", paddingRight: 24 }}
								></Col>
							</Row>
						</div>
						{exclusivesList?.data?.length > 3 && (
							<div className="viewWrapper">
								<Button
									className="secReset moreBtn mt20"
									type="default"
									htmlType="button"
									onClick={() => {
										history.push({
											pathname: "/nikoiq_exclusives",
											state: {
												company: id,
												name: organizationsDetailData?.name,
											},
										});
									}}
								>
									View more
									<img src={imgSource?.right_arrow_blue}></img>
								</Button>
							</div>
						)}
					</div>
				</div> */}
				<div className="BlogBox mt20" id="exclusiveSection">
					<div className="RowBox">
						<div className="Global_title">
							<h2>NikoIQ Exclusives</h2>
						</div>
						<div className="Insights_List">
							<ul className="ProlistBox">
								{exclusivesList?.data && exclusivesList?.data?.length > 0 ? (
									exclusivesList?.data?.slice(0, 3)?.map((vo, i) => {
										const date = new Date(vo?.date);
										const formattedDate = formatDate(date);
										const showAll = showAllTags[i] || false;

										return (
											<li key={i}>
												<div className="insight_list_cont">
													<h3>
														<div style={{ display: "block" }}>
															<div
																className="insight_list_title"
																onClick={() => {
																	history.push({
																		pathname: "/nikoiq_exclusives",
																		state: {
																			company: organizationsDetailData?.id,
																		},
																	});
																}}
															>
																{vo?.title}
															</div>
														</div>

														<div className="insight_list_date_style">
															{formattedDate}
														</div>
													</h3>
													<div style={{ display: "flex" }}>
														{vo?.area?.length > 0 && (
															<span
																className="listLink"
																onClick={() => {
																	history.push({
																		pathname: "/news_articles/VANA",
																		state: { area: vo?.area },
																	});
																}}
															>
																<img
																	src={imgSource?.map_icon}
																	style={{ marginRight: "5px" }}
																></img>
																{vo?.area?.length > 0 && vo?.area?.join(", ")}
															</span>
														)}
														{vo?.region?.length > 0 && (
															<span
																className="listLink"
																onClick={() => {
																	history.push({
																		pathname: "/news_articles/VANA",
																		state: {
																			region: vo?.region,
																		},
																	});
																}}
															>
																<img
																	src={imgSource?.globe_gray_icon}
																	style={{ marginRight: "5px" }}
																></img>
																{vo?.region?.length > 0 &&
																	vo?.region?.join(", ")}
															</span>
														)}
														{vo?.type && (
															<span
																className="listLink"
																onClick={() => {
																	history.push({
																		pathname: "/news_articles/VANA",
																		state: {
																			type: vo?.type,
																			subType: vo?.subType,
																		},
																	});
																}}
															>
																<img
																	src={imgSource?.folder_icon}
																	style={{ marginRight: "5px" }}
																></img>
																{vo?.type}
															</span>
														)}
													</div>
													<div className="Tag_List_Box">
														{vo?.withGames
															?.slice(0, showAll ? vo?.withGames?.length : 3)
															?.map((vi, iii) => (
																<span
																	className="game-title"
																	key={iii}
																	onClick={() => {
																		history.push(
																			`/games_organizations/games/details/${vi?.id}`
																		);
																	}}
																>
																	{vi?.gameEn}
																</span>
															))}
														{vo?.withCompanys
															?.slice(0, showAll ? vo?.withCompanys?.length : 3)
															?.map((vi, ii) => (
																<span
																	className="org-title"
																	key={ii}
																	onClick={() => {
																		history.push(
																			`/games_organizations/organizations/details/${vi?.id}`
																		);
																	}}
																>
																	{vi?.name}
																</span>
															))}
														{(vo?.withCompanys?.length > 3 ||
															vo?.withGames?.length > 3) && (
															<span
																className="show-all"
																onClick={() => toggleShowAll(i)}
															>
																{showAll ? "Show less..." : "Show all..."}
															</span>
														)}
													</div>
													<div
														className="insght_lst_info"
														onClick={() => {
															history.push({
																pathname: "/nikoiq_exclusives",
																state: { company: organizationsDetailData?.id },
															});
														}}
													>
														{vo?.subHeader?.replace(/<[^>]+>/g, "")}
													</div>
												</div>
											</li>
										);
									})
								) : (
									<CustomEmpty />
								)}
							</ul>
						</div>
						{exclusivesList?.data?.length > 3 && (
							<div className="viewWrapper">
								<Button
									className="secReset moreBtn mt20"
									type="default"
									htmlType="button"
									onClick={() => {
										history.push({
											pathname: "/nikoiq_exclusives",
											state: {
												company: organizationsDetailData?.id,
											},
										});
									}}
								>
									View more
									<img src={imgSource?.right_arrow_blue}></img>
								</Button>
							</div>
						)}
					</div>
				</div>
				{/* Esports */}
				<div className="BlogBox mt50">
					<div className="RowBox">
						<div className="Global_title">
							<h2>Esports</h2>
						</div>
						<div className="Insights_List">
							<ul className="ProlistBox">
								{EsportsList?.error != 1009 && EsportsList?.total == 0 && (
									<CustomEmpty />
								)}
								{EsportsList?.data && EsportsList?.error != 1009
									? EsportsList?.data?.slice(0, 3)?.map((vo, i) => {
											const date = new Date(vo?.date);
											const formattedDate = formatDate(date);
											const showAll = showAllTags[i] || false;
											return (
												<li key={i}>
													<div className="insight_list_cont">
														<h3>
															<div>
																{/* <span
																	onClick={() => {
																		history.push({
																			pathname: "/news_articles/VANA",
																			state: { type: vo?.type },
																		});
																	}}
																>
																	[{vo?.type?.trim()}]
																</span> */}
																<div
																	style={{ cursor: "pointer" }}
																	onClick={() => {
																		history.push(
																			`/news_articles/vana/details/${vo?.id}`
																		);
																	}}
																>
																	{vo?.title}
																</div>
															</div>

															<div className="insight_list_date_style">
																{formattedDate}
															</div>
														</h3>
														<div style={{ display: "flex" }}>
															{vo?.area?.length > 0 && (
																<span
																	className="listLink"
																	onClick={() => {
																		history.push({
																			pathname: "/news_articles/VANA",
																			state: { area: vo?.area },
																		});
																	}}
																>
																	<img
																		src={imgSource?.map_icon}
																		style={{ marginRight: "5px" }}
																	></img>
																	{vo?.area?.length > 0 && vo?.area?.join(", ")}
																</span>
															)}
															{vo?.region?.length > 0 && (
																<span
																	className="listLink"
																	onClick={() => {
																		history.push({
																			pathname: "/news_articles/VANA",
																			state: {
																				region: vo?.region,
																			},
																		});
																	}}
																>
																	<img
																		src={imgSource?.globe_gray_icon}
																		style={{ marginRight: "5px" }}
																	></img>
																	{vo?.region?.length > 0 &&
																		vo?.region?.join(", ")}
																</span>
															)}
															{vo?.type && (
																<span
																	className="listLink"
																	onClick={() => {
																		history.push({
																			pathname: "/news_articles/VANA",
																			state: {
																				type: vo?.type,
																				subType: vo?.subType,
																			},
																		});
																	}}
																>
																	<img
																		src={imgSource?.folder_icon}
																		style={{ marginRight: "5px" }}
																	></img>
																	{vo?.type}
																</span>
															)}
														</div>
														<div className="Tag_List_Box">
															{vo?.withGames
																?.slice(0, showAll ? vo?.withGames?.length : 3)
																?.map((vi, iii) => (
																	<span
																		className="game-title"
																		key={iii}
																		onClick={() => {
																			history.push(
																				`/games_organizations/games/details/${vi?.id}`
																			);
																		}}
																	>
																		{vi?.gameEn}
																	</span>
																))}
															{vo?.withCompanys
																?.slice(
																	0,
																	showAll ? vo?.withCompanys?.length : 3
																)
																?.map((vi, ii) => (
																	<span
																		className="org-title"
																		key={ii}
																		onClick={() => {
																			history.push(
																				`/games_organizations/organizations/details/${vi?.id}`
																			);
																		}}
																	>
																		{vi?.name}
																	</span>
																))}
															{(vo?.withCompanys?.length > 3 ||
																vo?.withGames?.length > 3) && (
																<span
																	className="show-all"
																	onClick={() => toggleShowAll(i)}
																>
																	{showAll ? "Show less..." : "Show all..."}
																</span>
															)}
														</div>
														<div
															className="insght_lst_info"
															onClick={() => {
																history.push(
																	`/news_articles/vana/details/${vo?.id}`
																);
															}}
														>
															{vo?.content?.replace(/<[^>]+>/g, "")}
														</div>
													</div>
												</li>
											);
									  })
									: customizeRenderEmpty()}
							</ul>
						</div>
						{EsportsList?.data?.length > 3 && (
							<div className="viewWrapper">
								<Button
									className="secReset moreBtn mt20"
									type="default"
									htmlType="button"
									onClick={() => {
										history.push({
											pathname: "/esports",
											state: {
												company: id,
												name: organizationsDetailData?.name,
											},
										});
									}}
								>
									View more
									<img src={imgSource?.right_arrow_blue}></img>
								</Button>
							</div>
						)}
					</div>
				</div>
			</Spin>
		</div>
	);
};
