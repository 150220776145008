import React, { useState, useEffect } from "react";
import "./index.less";
import imgSource from "../../../assets";
import { effect, useStore, reducer } from "../../../utils/dva16";
import formatDate from "@/utils/dateFormater";
import {
	EGetTop,
	EGet,
	NBlog,
	EGetOne,
	RSetState,
} from "../../../models/contants";
import { Helmet } from "react-helmet";
import { Button } from "antd";

export default (props) => {
	const { history } = props;
	const {
		location: { state },
	} = history;
	const { detailList, loading, topBlogList } = useStore(NBlog);
	const [topBlogs, setTopBlogs] = useState([]);
	const [showAllTags, setShowAllTags] = useState({});
	const [showAllItems, setShowAllItems] = useState(false);

	useEffect(() => {
		//组件渲染时滚动到页面的顶部
		window.scrollTo(0, 0);
	}, []);

	const toggleShowAllItems = () => {
		setShowAllItems((prevShowAll) => !prevShowAll);
	};
	const toggleShowAll = (index) => {
		setShowAllTags((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};

	useEffect(() => {
		effect(NBlog, EGetOne, { id: props?.match?.params?.id });
		return () => {
			reducer(NBlog, RSetState, {
				detailList: null,
			});
		};
	}, [props]);
	useEffect(() => {
		effect(NBlog, EGetTop, { page: 1, pageSize: 10 });
	}, []);
	useEffect(() => {
		if (topBlogList) {
			//筛选出不是当前文章的数据 | Filter out data that is not the current article
			let arr = topBlogList?.data?.filter((item) => {
				return item?.id != props?.match?.params?.id;
			});
			setTopBlogs(arr);
		}
	}, [topBlogList, props]);

	//从sessionStorage中获取当前页面和每页的项目数，并将页面减1。然后，它调用effect函数以获取新页面的数据 | Retrieve the current page and the number of items per page from sessionStorage, and subtract 1 from the page. Then, it calls the effect function to obtain the data for the new page
	const prev = () => {
		let page = sessionStorage.getItem("newsPage");
		let perPage = sessionStorage.getItem("newsPerPage");
		let index = sessionStorage.getItem("newsIndex");
		if (page == 1 && index == 0) {
			return;
		}
		if (page > 1 && index == 0) {
			sessionStorage.setItem("newsPage", page - 1);
			effect(
				NBlog,
				EGet,
				{
					page: page - 1,
					pageSize: perPage,
				},
				(res) => {
					if (res) {
						sessionStorage.setItem("newsArr", JSON.stringify(res?.data));
						let arr = JSON.parse(sessionStorage.getItem("newsArr"));
						let id = arr[Number(perPage - 1)]?.id;
						sessionStorage.setItem("newsIndex", perPage - 1);
						history.push(`/news_articles/blog_posts/details/${id}`);
					}
				}
			);
		} else {
			let arr = JSON.parse(sessionStorage.getItem("newsArr"));
			let id = arr[Number(index - 1)].id;
			sessionStorage.setItem("newsIndex", index - 1);
			history.push(`/news_articles/blog_posts/details/${id}`);
		}
	};
	//从sessionStorage中获取当前页面和每页的项目数，并将页面加1。然后，它调用effect函数以获取新页面的数据 | Retrieve the current page and the number of items per page from sessionStorage, and add 1 to the page. Then, it calls the effect function to obtain the data for the new page
	const next = () => {
		let page = sessionStorage.getItem("newsPage");
		let lastPage = sessionStorage.getItem("newsLastPage");
		let perPage = sessionStorage.getItem("newsPerPage");
		let index = sessionStorage.getItem("newsIndex");

		if (page == lastPage && index == perPage - 1) {
			return;
		}
		if (page >= 1 && index == perPage - 1) {
			let page1 = Number(page) + 1;
			sessionStorage.setItem("newsPage", page1);
			effect(
				NBlog,
				EGet,
				{
					page: page1,
					pageSize: perPage,
				},
				(res) => {
					if (res) {
						sessionStorage.setItem("newsArr", JSON.stringify(res?.data));
						let arr = JSON.parse(sessionStorage.getItem("newsArr"));
						let id = arr[Number(0)]?.id;
						sessionStorage.setItem("newsIndex", Number(0));
						sessionStorage.setItem("newsLastPage", res?.lastPage);
						history.push(`/news_articles/blog_posts/details/${id}`);
					}
				}
			);
		} else {
			effect(
				NBlog,
				EGet,
				{
					page: page,
					pageSize: perPage,
				},
				(res) => {
					if (res) {
						let arr = JSON.parse(sessionStorage.getItem("newsArr"));
						let index2 = Number(index) + 1;
						let id = arr[index2].id;
						sessionStorage.setItem("newsIndex", index2);
						sessionStorage.setItem("newsLastPage", res.lastPage);
						history.push(`/news_articles/blog_posts/details/${id}`);
					}
				}
			);
		}
	};
	const date = new Date(detailList && detailList?.createdAt);
	const formattedDate = formatDate(date);
	return (
		<div className="newDetail">
			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>
				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          				gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
        	`}
				</script>
			</Helmet>
			<div className="newContainer">
				<div style={{ margin: "0 15rem" }}>
					<div className="title">{detailList?.title}</div>
					<div className="">
						{detailList?.withGames
							?.slice(0, showAllItems ? detailList?.withGames?.length : 3)
							?.map((item) => (
								<span
									key={item?.id}
									className="gameTags"
									onClick={() => {
										history.push({
											pathname: "/news_articles/blog_posts",
											state: { game: item?.id },
										});
									}}
								>
									{item?.gameEn}
								</span>
							))}
						{detailList?.withCompanys
							?.slice(0, showAllItems ? detailList?.withCompanys?.length : 3)
							?.map((item) => (
								<span
									key={item?.id}
									className="companyTags"
									onClick={() => {
										history.push({
											pathname: "/news_articles/blog_posts",
											state: { company: item?.id },
										});
									}}
								>
									{item?.name}
								</span>
							))}
						{(detailList?.withCompanys?.length > 3 ||
							detailList?.withGames?.length > 3) && (
							<span className="show-all" onClick={toggleShowAllItems}>
								{showAllItems ? "Show less..." : "Show all..."}
							</span>
						)}
					</div>
					<div className="headerInfo">
						<div style={{ display: "flex" }}>
							{detailList?.area?.length > 0 && (
								<span
									className="link"
									onClick={() => {
										history.push({
											pathname: "/news_articles/blog_posts",
											state: { area: detailList?.area },
										});
									}}
								>
									<img
										src={imgSource?.map_icon}
										style={{ marginRight: "5px" }}
									></img>
									{detailList?.area?.length > 0 && detailList?.area?.join(", ")}
								</span>
							)}
							{detailList?.region?.length > 0 && (
								<span
									className="link"
									onClick={() => {
										history.push({
											pathname: "/news_articles/blog_posts",
											state: { region: detailList?.region },
										});
									}}
								>
									<img
										src={imgSource?.globe_gray_icon}
										style={{ marginRight: "5px" }}
									></img>
									{detailList?.region?.length > 0 &&
										detailList?.region?.join(", ")}
								</span>
							)}
							{detailList?.type && (
								<span
									className="link"
									onClick={() => {
										history.push({
											pathname: "/news_articles/blog_posts",
											state: {
												type: detailList?.type,
												subType: detailList?.subType,
											},
										});
									}}
								>
									<img
										src={imgSource?.folder_icon}
										style={{ marginRight: "5px" }}
									></img>
									{detailList?.type}
								</span>
							)}
						</div>
						<div>
							<span>{formattedDate}</span>
						</div>
					</div>
					<div
						className="contentBox"
						dangerouslySetInnerHTML={{
							__html: detailList?.description,
						}}
					></div>
					<div className="footerInfo">
						<div className="link">
							<a href={detailList?.link} target="_blank">
								{detailList?.link}
							</a>
						</div>
						<div className="breaker"></div>

						<div className="btns">
							<div className="btnIcon" onClick={prev}>
								<img src={imgSource?.prev_icon}></img>
							</div>
							<div
								className="btnIcon"
								onClick={next}
								style={{ marginLeft: "1rem" }}
							>
								<img src={imgSource?.next_icon}></img>
							</div>
						</div>
					</div>
				</div>

				<div className="topBlogs">
					<div className="title">Recommended Blog Articles</div>
					{topBlogs?.slice(0, 3)?.map((item, index) => {
						const date = new Date(item?.date);
						const formattedDate = formatDate(date);

						const showAll = showAllTags[index] || false;

						return (
							<>
								<div className="newsItem" key={index}>
									<div
										className="head"
										onClick={() => {
											history.push(
												`/news_articles/blog_posts/details/${item?.id}`
											);
											window.scroll(0, 0);
										}}
									>
										<span>{item?.title}</span>
										<span className="topDate">{formattedDate}</span>
									</div>

									<div style={{ display: "flex" }}>
										{item?.area?.length > 0 && (
											<span
												className="listLink"
												onClick={() => {
													history.push({
														pathname: "/news_articles/blog_posts",
														state: { area: item?.area },
													});
												}}
											>
												<img
													src={imgSource?.map_icon}
													style={{ marginRight: "5px" }}
												></img>
												{item?.area?.length > 0 && item?.area?.join(", ")}
											</span>
										)}
										{item?.region?.length > 0 && (
											<span
												className="listLink"
												onClick={() => {
													history.push({
														pathname: "/news_articles/blog_posts",
														state: { region: item?.region },
													});
												}}
											>
												<img
													src={imgSource?.globe_gray_icon}
													style={{ marginRight: "5px" }}
												></img>
												{item?.region?.length > 0 && item?.region?.join(", ")}
											</span>
										)}
										{item?.type && (
											<span
												className="listLink"
												onClick={() => {
													history.push({
														pathname: "/news_articles/blog_posts",
														state: {
															type: item?.type,
															subType: item?.subType,
														},
													});
												}}
											>
												<img
													src={imgSource?.folder_icon}
													style={{ marginRight: "5px" }}
												></img>
												{item?.type}
											</span>
										)}
									</div>

									{item?.withGames
										?.slice(0, showAll ? item?.withGames?.length : 3)
										?.map((item) => (
											<span
												key={item?.id}
												className="gameTags"
												onClick={() => {
													history.push({
														pathname: "/news_articles/blog_posts",
														state: { game: item?.id },
													});
												}}
											>
												{item?.gameEn}
											</span>
										))}
									{item?.withCompanys
										?.slice(0, showAll ? item?.withCompanys?.length : 3)
										?.map((item) => (
											<span
												key={item?.id}
												className="companyTags"
												onClick={() => {
													history.push({
														pathname: "/news_articles/blog_posts",
														state: { company: item?.id },
													});
												}}
											>
												{item?.name}
											</span>
										))}
									{(item?.withCompanys?.length > 3 ||
										item?.withGames?.length > 3) && (
										<span
											className="show-all"
											onClick={() => toggleShowAll(index)}
										>
											{showAll ? "Show less..." : "Show all..."}
										</span>
									)}

									<div
										className="text"
										onClick={() => {
											history.push(
												`/news_articles/blog_posts/details/${item?.id}`
											);
											window.scroll(0, 0);
										}}
									>
										{item?.description?.replace(/<[^>]+>/g, "")}
									</div>
								</div>
							</>
						);
					})}
				</div>
				{topBlogs?.length > 3 && (
					<div className="viewWrapper">
						<Button
							className="secReset moreBtn mt20"
							type="default"
							htmlType="button"
							onClick={() => {
								history.push({
									pathname: "/news_articles/blog_posts",
								});
							}}
						>
							View more <img src={imgSource?.right_arrow_blue}></img>
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};
